import "babel-polyfill";
import ToggleHeaderNav from './modules/ToggleHeaderNav.js';
import ScrollTo from './modules/ScrollTo.js';

document.addEventListener("DOMContentLoaded", function() {
  for (const e of document.querySelectorAll('[data-toggle-nav]')) {
    new ToggleHeaderNav(e);
  }

  for (const e of document.querySelectorAll('[data-scroll-to]')) {
    new ScrollTo(e);
  }
});
